// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import {handleLogout} from "../authentication"
import {setUserStrings} from "../loca/userString"

export const getGifts = createAsyncThunk('appAdminEventGifts/getGifts', async (pageId) => {
	const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/gifts/${pageId}`)
	return response.data
})

export const deleteGift = createAsyncThunk('appAdminEventGifts/deleteGift', async (giftId, { dispatch}) => {
	const response = await axios.delete(`${process.env.REACT_APP_API_ROOT}/gift/${giftId}`)
	dispatch(getGifts(response.data.pageId))
	return giftId
})

export const setImage = createAsyncThunk('appAdminEventGifts/setImage', async ({giftId, image}) => {
	if (image !== null)
	{
		const formData = new FormData()
		formData.append("image", image)
		await axios.post(`${process.env.REACT_APP_API_ROOT}/gift/${giftId}/image`, formData)
	}
})

export const updateGift = createAsyncThunk('appAdminEventGifts/deleteGift', async (giftData, { dispatch}) =>
{
	const response = await axios.patch(`${process.env.REACT_APP_API_ROOT}/gift/${giftData.id}`, giftData)
	await dispatch(setImage({giftId: giftData.id, image:giftData["image"]}))
	await setUserStrings({pageId: response.data.pageId, entries: giftData.locaStrings})
	dispatch(getGifts(response.data.pageId))
	return giftData.id
})

export const addGift = createAsyncThunk('appAdminEventGifts/deleteGift', async (giftData, { dispatch}) => {

	const locaData = giftData.locaStrings
	delete giftData.locaStrings
	const newGift = await axios.post(`${process.env.REACT_APP_API_ROOT}/gift/${giftData.pageId}`, giftData)
	await dispatch(setImage({giftId: newGift.data.id, image:giftData["image"]}))

	for (const entry in locaData)
	{
		locaData[entry].key = `strGift_${newGift.data.id}_${locaData[entry].key}`
	}
	await setUserStrings({pageId: giftData.pageId, entries: locaData})
	dispatch(getGifts(giftData.pageId))
	return newGift.data.id
})

export const appAdminEventGiftsSlice = createSlice({
	name: 'appAdminEventGifts',
	initialState: {
		gifts: []
	},
	reducers: {},
	extraReducers: builder => {
		builder
		.addCase(getGifts.fulfilled, (state, action) => {
			state.gifts = action.payload
			for (const giftId in state.gifts)
			{
				const gift = state.gifts[giftId]
				let amountLeft = gift.price * gift.amount
				for (const purchaseId in gift.purchaseEntries)
				{
					amountLeft -= gift.purchaseEntries[purchaseId].amount
				}
				
				state.gifts[giftId].isSoldOut = amountLeft <= 0 || gift.status === 'DISABLED'
				state.gifts[giftId].amountLeft = amountLeft
				
				if (!gift.imageFileName)
				{
					gift.imageFileName = "gift.png"
				}
			}
		})
		builder.addCase(handleLogout, (state) =>
		{
			state.gifts = []
			state.selectedGift = null
		})
	}
})

export default appAdminEventGiftsSlice.reducer