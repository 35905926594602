// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getGifts = createAsyncThunk('appEventGifts/getGifts', async (pageId) => {
	const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/gifts/${pageId}`)
	return response.data
})

export const buyGifts = createAsyncThunk('appEventGifts/buyGifts', async (data) => {
	const response = await axios.post(`${process.env.REACT_APP_API_ROOT}/purchase`, data)
	return response.data
})

export const appEventGiftsSlice = createSlice({
	name: 'appEventGifts',
	initialState: {
		gifts: null,
		purchaseData: [],
		error: ""
	},
	reducers: {},
	extraReducers: builder => {
		builder
		.addCase(getGifts.fulfilled, (state, action) => {
			state.gifts = action.payload
			for (const giftId in state.gifts)
			{
				const gift = state.gifts[giftId]
				
				if (!gift.imageFileName)
				{
					gift.imageFileName = "gift.png"
				}
				if (gift.status === "DISABLED")
				{
					state.gifts[giftId].isSoldOut = true
				}
				else
				{
					let amountLeft = gift.price * gift.amount
					for (const purchaseId in gift.purchaseEntries)
					{
						amountLeft -= gift.purchaseEntries[purchaseId].amount
					}
					
					state.gifts[giftId].isSoldOut = amountLeft <= 0
				}
			}
		})
		.addCase(buyGifts.pending, (state) =>
		{
			state.error = ""
			state.purchaseData = []
		})
		.addCase(buyGifts.fulfilled, (state, action) =>
		{
			state.purchaseData = action.payload
			
			let totalAmount = 0
			for (const purchaseId in state.purchaseData.entries)
			{
				totalAmount += state.purchaseData.entries[purchaseId].amount
			}
			
			state.purchaseData.totalAmount = totalAmount
		})
		.addCase(buyGifts.rejected, (state) =>
		{
			state.purchaseData = []
			state.error = "Error"
		})
	}
})

export default appEventGiftsSlice.reducer