// ** Reducers Imports
import auth from './authentication'

import users from './admin/users'
import pages from './admin/pages'

import userData from './userData'
import userPages from './userPages'

import eventGifts from './event/gifts'
import eventDetails from './event/eventDetails'

import userStrings from './loca/userString'

export default  {
	auth,
	userData,
	users,
	pages,
	userPages,
	eventGifts,
	eventDetails,
	userStrings
}