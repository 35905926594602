// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

const config = useJwt.jwtConfig
import jwt from 'jwt-decode'

const initialRoles = () =>
{
  const item = window.localStorage.getItem('userRoles')
  return item ? JSON.parse(item) : []
}

const initialUserId = () =>
{
  const item = window.localStorage.getItem('userId')
  return item ? JSON.parse(item) : null
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userRoles: initialRoles(),
    userId: initialUserId()
  },
  reducers: {
    handleLogin: (state, action) => {
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem(config.storageTokenKeyName, action.payload.accessToken)
      localStorage.setItem(config.storageRefreshTokenKeyName, action.payload.refreshToken)
      
      const tokenData = jwt(action.payload[config.storageTokenKeyName])
      state['userRoles'] = tokenData['roles']
      action.payload['userRoles'] = tokenData['roles']
      localStorage.setItem('userRoles', JSON.stringify(state['userRoles']))
      
      state['userId'] = tokenData['userId']
      action.payload['userId'] = tokenData['userId']
      localStorage.setItem('userId', JSON.stringify(state['userId']))
    },
    handleLogout: state => {
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      state['userRoles'] = null
      state['userId'] = null
      // ** Remove accessToken & refreshToken from localStorage
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
      localStorage.removeItem('userRoles')
      localStorage.removeItem('userId')
    }
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
