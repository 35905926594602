// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getEventDetails = createAsyncThunk('appAdminEventDetails/getPageData', async id => {
	const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/page/${id}`)
	return response.data
})

export const setEventDetails = async ({id, data}) => {
	return axios.patch(`${process.env.REACT_APP_API_ROOT}/page/${id}`, data)
}

export const appAdminEventDetailsSlice = createSlice({
	name: 'appAdminEventDetails',
	initialState: {
		data: null
	},
	reducers: {},
	extraReducers: builder => {
		builder
		.addCase(getEventDetails.fulfilled, (state, action) => {
			state.data = action.payload
		})
	}
})

export default appAdminEventDetailsSlice.reducer
