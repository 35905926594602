// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import i18next from 'i18next'

export const getPageLoca = createAsyncThunk('appPublicUserStrings/getPageLoca', async (pageId) =>
{
	const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/userString/${pageId}`)
	return response.data
})

const addRecord = (state, entry) =>
{
	const key = entry.key
	const value = entry.value
	const lang = entry.lang
	
	state.pageLoca = {...state.pageLoca, [lang]:{...state.pageLoca[lang], [key] : value}}
}

export const appPublicUserStringsSlice = createSlice({
	name: 'appPublicUserStrings',
	initialState: {
		pageLoca: null
	},
	reducers: {},
	extraReducers: builder => {
		builder
		.addCase(getPageLoca.pending, (state) =>
		{
			state.pageLoca = null
		})
		.addCase(getPageLoca.fulfilled, (state, action) =>
		{
			state.pageLoca = {}
			action.payload.forEach((entry) =>
			{
				addRecord(state, entry)
			})
			
			for (const lang in state.pageLoca)
			{
				i18next.addResourceBundle(lang, 'userStrings', state.pageLoca[lang], true, true)
			}
			console.log('resources: ', i18next) // prints out en-> translations -> ABSENCE_DETAIL: 'Absence Detail' ... and others
		})
	}
})

export default appPublicUserStringsSlice.reducer