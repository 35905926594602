// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/users/`)
  return response.data
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/user/`, { id })
  return response.data.user
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch }) => {
  await axios.post(`${process.env.REACT_APP_API_ROOT}/user`, user)
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch}) => {
  await axios.delete(`${process.env.REACT_APP_API_ROOT}/user/${id}`)
  await dispatch(getAllData())
  return id
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appUsersSlice.reducer
