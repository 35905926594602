// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getUserString = createAsyncThunk('appUserString/getUserString', async ({pageId, stringId, lang}) =>
{
	const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/userString/${pageId}/${stringId}/${lang}`)
	return response.data
})

export const getUserStrings = createAsyncThunk('appUserString/getUserStrings', async ({pageId, stringId}) =>
{
	const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/userString/${pageId}/${stringId}`)
	return response.data
})

export const setUserStrings = async ({pageId, entries}) =>
{
	return axios.post(`${process.env.REACT_APP_API_ROOT}/userString/${pageId}`, entries)
}

const addRecord = (state, entry) =>
{
	const key = entry.key
	const value = entry.value
	const lang = entry.lang

	state.dbStrings = {...state.dbStrings, [key]:{...state.dbStrings[key], [lang] : value}}
}

export const appUserStringSlice = createSlice({
	name: 'appUserStrings',
	initialState: {
		dbStrings: {}
	},
	reducers: {},
	extraReducers: builder => {
		builder
		.addCase(getUserString.fulfilled, (state, action) =>
		{
			addRecord(state, action.payload)
		})
		.addCase(getUserStrings.fulfilled, (state, action) =>
		{
			delete state.dbStrings[action.meta.arg['stringId']]
			action.payload.forEach((entry) =>
			{
				addRecord(state, entry)
			})
		})
	}
})

export default appUserStringSlice.reducer
