// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import {handleLogout} from "./authentication"

export const getPagesForUser = createAsyncThunk('appUserPages/getPagesForUser', async (userId) => {
	const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/pages/${userId}`)
	return response.data
})

export const getPage = createAsyncThunk('appUserPages/getPage', async id => {
	const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/page/${id}`)
	return response.data.page
})

export const appUserPagesSlice = createSlice({
	name: 'appUserPages',
	initialState: {
		pages: [],
		selectedPage: null
	},
	reducers: {},
	extraReducers: builder => {
		builder
		.addCase(getPagesForUser.fulfilled, (state, action) => {
			state.pages = action.payload
		})
		.addCase(getPage.fulfilled, (state, action) => {
			state.selectedPage = action.payload
		})
		builder.addCase(handleLogout, (state) =>
		{
			state.pages = []
			state.selectedPage = null
		})
	}
})

export default appUserPagesSlice.reducer
