// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'

import publicUserStrings from './loca/userStrings'

import gifts from './event/gifts'
import page from './event/page'

import admin from './admin/reducers'

const rootReducer = {
  navbar,
  layout,
  ...admin,
  gifts,
  page,
  publicUserStrings
}

export default rootReducer