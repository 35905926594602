// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import {getPageLoca} from "../loca/userStrings"

export const getPage = createAsyncThunk('appEventPage/getPage', async (pageId, { dispatch}) =>
{
	await dispatch(getPageLoca(pageId))
	
	const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/page/${pageId}`)
	return response.data
})
export const appEventPageSlice = createSlice({
	name: 'appEventPage',
	initialState: {
		pageData: null,
		error: null
	},
	reducers: {},
	extraReducers: builder => {
		builder
		.addCase(getPage.pending, (state) =>
		{
			state.pageData = null
		})
		.addCase(getPage.fulfilled, (state, action) =>
		{
			state.pageData = action.payload
		})
		.addCase(getPage.rejected, (state) =>
		{
			state.error = "error"
		})
	}
})

export default appEventPageSlice.reducer