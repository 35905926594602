// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import {handleLogout, handleLogin} from "./authentication"

export const submitData = createAsyncThunk('appProfile/setData', async (settings) =>
{
	const response = await axios.post(`${process.env.REACT_APP_API_ROOT}/account_settings/`, settings)
	return response.data
})

export const getUserData = createAsyncThunk('appProfile/getData', async () =>
{
	const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/account_settings`)
	return response.data
})

const initialUser = () =>
{
	const item = window.localStorage.getItem('userData')
	//** Parse stored json or if none return initialValue
	return item ? JSON.parse(item) : {}
}

export const appUserDataSlice = createSlice({
	name: 'appProfile',
    initialState:
    {
	    data: initialUser()
	},
    extraReducers: builder =>
	{
		builder.addCase(submitData.fulfilled, (state, data) =>
		{
			state.data = data.payload
			localStorage.setItem('userData', JSON.stringify(data.payload))
		})
		builder.addCase(getUserData.fulfilled, (state, data) =>
		{
			state.data = data.payload
			localStorage.setItem('userData', JSON.stringify(data.payload))
		})
		builder.addCase(handleLogin, () =>
		{
			getUserData.call()
		})
		builder.addCase(handleLogout, (state) =>
		{
			localStorage.removeItem('userData')
			state.data = {}
		})
	}
})

export const { setUserData } = appUserDataSlice.actions
export default appUserDataSlice.reducer
