// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appPages/getAllData', async () => {
	const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/pages/`)
	return response.data
})

export const getPage = createAsyncThunk('appPages/getPage', async id => {
	const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/pages/${id}`)
	return response.data.page
})

export const addPage = createAsyncThunk('appPages/addPage', async (page, { dispatch }) => {
	await axios.post(`${process.env.REACT_APP_API_ROOT}/page`, page)
	await dispatch(getAllData())
	return page
})

export const deletePage = createAsyncThunk('appPages/deletePage', async (id, { dispatch}) => {
	await axios.delete(`${process.env.REACT_APP_API_ROOT}/page/${id}`)
	await dispatch(getAllData())
	return id
})

export const appPagesSlice = createSlice({
	name: 'appPages',
	initialState: {
		data: [],
		selectedPage: null
	},
	reducers: {},
	extraReducers: builder => {
		builder
		.addCase(getAllData.fulfilled, (state, action) => {
			state.data = action.payload
		})
		.addCase(getPage.fulfilled, (state, action) => {
			state.selectedPage = action.payload
		})
	}
})

export default appPagesSlice.reducer
